
import {
  defineComponent,
  ref,
  reactive,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import ModalComponent from '@/components/ModalComponent.vue';
import Button from '@/components/ui/ButtonComponent.vue';
import Input from '@/components/ui/InputComponent.vue';

import { SIGNUP_REQUEST } from '@/store/actions/user';
import { SHOW_MODAL } from '@/store/actions/modal';

export default defineComponent({
  name: 'RegistrationModal',
  components: {
    ModalComponent,
    Button,
    Input,
  },
  setup() {
    const store = useStore();

    const { t } = useI18n({
      useScope: 'global',
    });

    const loading = ref(false);

    const formData = reactive({
      firstName: {
        value: '',
        error: undefined,
      },
      lastName: {
        value: '',
        error: undefined,
      },
      email: {
        value: '',
        error: undefined,
      },
      password: {
        value: '',
        error: undefined,
      },
      passwordRepeat: {
        value: '',
        error: undefined,
      },
    }) as any;

    const hasFormError = computed(() => {
      let hasError = false;

      Object.keys(formData).forEach((key) => {
        if (formData[key].error !== undefined) {
          hasError = true;
        }
      });

      return hasError;
    });

    const provideUserRegistration = () => {
      loading.value = true;

      Object.keys(formData).forEach((key) => {
        formData[key].error = undefined;
      });

      if (!formData.firstName.value) {
        formData.firstName.error = t('profile.signup.first_name.error');
      }

      if (!formData.lastName.value) {
        formData.lastName.error = t('profile.signup.last_name.error');
      }

      if (!formData.email.value) {
        formData.email.error = t('profile.signup.email.error');
      }

      if (!formData.password.value) {
        formData.password.error = t('profile.signup.password.error');
      }

      if (formData.password.value !== formData.passwordRepeat.value) {
        formData.passwordRepeat.error = t('profile.signup.password_repeat.error');
      }

      if (!hasFormError.value) {
        store.dispatch(SIGNUP_REQUEST, {
          firstName: formData.firstName.value,
          lastName: formData.lastName.value,
          email: formData.email.value,
          password: formData.password.value,
        }).then((payload) => {
          window.location.reload();
        }).catch((error) => {
          if (error.data) {
            const { message, data } = error.data;

            if (data.field && formData[data.field]) {
              formData[data.field].error = message;
            } else {
              alert(message);
            }
          } else {
            alert(t('profile.signup.error'));
          }
        }).finally(() => {
          loading.value = false;
        });
      } else {
        loading.value = false;
      }
    };

    return {
      ...formData,
      provideUserRegistration,
      loading,
      store,
      SHOW_MODAL,
    };
  },
});
