import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import store from '@/store';

import { i18n } from '@/i18n';

import ChannelsView from '../views/channels';

const isAuthenticated = (to: any, from: any, next: any) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const isAnonymous = (to: any, from: any, next: any) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const isSubscribed = (to: any, from: any, next: any) => {
  if (store.getters.isAuthenticated && (store.state as any).user.profile.subscription === true) {
    next();
    return;
  }
  next('/subscription');
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: ChannelsView,
    meta: {
      title: 'channels.title',
    },
  },
  {
    path: '/signals',
    name: 'signals',
    component: () => import(/* webpackChunkName: "signals" */ '../views/signals'),
    beforeEnter: isSubscribed,
    meta: {
      title: 'signals.title',
    },
  },
  {
    path: '/cabinet',
    name: 'cabinet',
    component: () => import(/* webpackChunkName: "cabinet" */ '../views/CabinetView.vue'),
    beforeEnter: isAuthenticated,
    meta: {
      title: 'profile.title',
    },
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import(/* webpackChunkName: "subscription" */ '../views/SubscriptionView.vue'),
    meta: {
      title: 'subscription.title',
    },
  },
  {
    path: '/copytrading',
    name: 'copytrading',
    component: () => import(/* webpackChunkName: "copytrading" */ '../views/CopytradingView.vue'),
    beforeEnter: isSubscribed,
    meta: {
      title: 'copytrading.title',
    },
  },
  {
    path: '/subscription/success',
    name: 'subscription_success',
    component: () => import(/* webpackChunkName: "subscription" */ '../views/SubscriptionSuccessView.vue'),
    meta: {
      title: 'subscription.title',
    },
  },
  {
    path: '/subscription/error',
    name: 'subscription_error',
    component: () => import(/* webpackChunkName: "subscription" */ '../views/SubscriptionErrorView.vue'),
    meta: {
      title: 'subscription.title',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.title = `${to.meta.title ? i18n.global.t(to.meta.title as string) : '404'} | SignalsHub`;

  next();
});

export default router;
