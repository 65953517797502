import { createApp } from 'vue';
import { useI18n } from 'vue-i18n';

import 'custom-vue-scrollbar/dist/style.css';

import Notifications from '@kyvg/vue3-notification';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './i18n';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

createApp(App, {
  setup() {
    const { t } = useI18n();
    return t;
  },
}).use(store).use(router).use(Notifications)
  .use(i18n)
  .mount('#app');
