import { createStore } from 'vuex';

import modal from './modules/modal';
import user from './modules/user';

export default createStore({
  modules: {
    modal,
    user,
  },
});
