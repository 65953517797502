
import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useNotification } from '@kyvg/vue3-notification';

import { VueSpinner } from 'vue3-spinners';

import CustomScrollbar from 'custom-vue-scrollbar';

import { Channel, CompleteChannel } from '@/rest-api/signals/assets/channels';

import StatisticChart from '@/components/charts/StatisticChart.vue';

import Button from '@/components/ui/ButtonComponent.vue';
import InlineSelector from '@/components/ui/InlineSelector.vue';

import VerifySvg from '@/components/icons/VerifySvg.vue';
import UserSvg from '@/components/icons/UserSvg.vue';
import TelegramEmptySvg from '@/components/icons/TelegramEmptySvg.vue';
import DirectionIcon from '@/components/icons/DirectionIcon.vue';

import { chartTypes } from '@/data/charts';
import { availableTimeframes } from '@/data/markets';

import * as api from '@/rest-api/signals';
import { InlineSelector as InlineSelectorModel } from '@/models/selector';

import { SHOW_MODAL } from '@/store/actions/modal';

export default defineComponent({
  name: 'ChannelComponent',
  components: {
    CustomScrollbar,
    StatisticChart,
    Button,
    VerifySvg,
    UserSvg,
    TelegramEmptySvg,
    InlineSelector,
    DirectionIcon,
    VueSpinner,
  },
  props: {
    channel: { type: Object as PropType<Channel | undefined>, required: true },
    market: { type: Object as PropType<InlineSelectorModel>, required: true },
    timeframe: { type: Object as PropType<InlineSelectorModel>, required: true },
  },
  emits: ['update:channel', 'update:timeframe', 'showChannels', 'showCalculator'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n({
      useScope: 'global',
    });
    const { notify } = useNotification();

    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    const fullChannel = ref<CompleteChannel | undefined>();
    const strategyIndex = ref(0);

    const chartType = ref<InlineSelectorModel>(chartTypes[0]);

    const isSubscriptionLoading = ref(false);

    const isHistoryButtonShown = ref(true);
    const isHistoryButtonLoading = ref(false);

    const currentChannel = computed<Channel | undefined>({
      get() {
        return props.channel;
      },
      set(newChannel) {
        emit('update:channel', newChannel);
      },
    });

    const setActiveChannel = (channel: Channel) => {
      fullChannel.value = undefined;

      api.getChannel(channel.id, props.timeframe.key).then((payload) => {
        if (!('error' in payload) && 'id' in payload) {
          fullChannel.value = payload;
          isHistoryButtonShown.value = payload.signals.length >= 30;

          if (strategyIndex.value > 0 && payload.strategies.length === 1) {
            strategyIndex.value = 0;
          }
        }
      });
    };

    const loadMoreSignals = () => {
      if (fullChannel.value && fullChannel.value.id) {
        isHistoryButtonLoading.value = true;

        api.getSignals(fullChannel.value.id, fullChannel.value.signals.length).then((payload) => {
          if (fullChannel.value) {
            fullChannel.value.signals.push(...payload);

            if (payload.length < 30) {
              isHistoryButtonShown.value = false;
            }

            isHistoryButtonLoading.value = false;
          }
        });
      }
    };

    const selectStrategy = (index: number) => {
      if (fullChannel.value?.strategies.length === 1) {
        notify({
          title: '<div class="sg__notify-arrow"></div>',
          text: t('channels.strategy.unknown'),
          duration: 3500,
          type: 'success',
        });
      } else {
        strategyIndex.value = index;
      }
    };

    const toggleSubscription = () => {
      if (!isAuthenticated.value) {
        store.commit(SHOW_MODAL, 'auth');
        return;
      }

      if (fullChannel.value) {
        isSubscriptionLoading.value = true;

        api.subscribe(fullChannel.value.id).then((payload) => {
          if (fullChannel.value) {
            fullChannel.value.subscribed = payload.status;
          }

          if (currentChannel.value !== undefined) {
            currentChannel.value.subscribed = payload.status;
          }
        }).finally(() => {
          isSubscriptionLoading.value = false;
        });
      }
    };

    const recalculateStrategies = () => {
      if (fullChannel.value !== undefined && fullChannel.value.id) {
        api.getChannel(fullChannel.value.id, props.timeframe.value).then((payload: CompleteChannel) => {
          if (fullChannel.value) {
            fullChannel.value.strategies = payload.strategies;
          }
        });
      }
    };

    const profitableCount = computed(() => {
      if (fullChannel.value && fullChannel.value.id && fullChannel.value.strategies[strategyIndex.value].totalSignals > 0) {
        return Math.round((fullChannel.value.strategies[strategyIndex.value].profitSignals / fullChannel.value.strategies[strategyIndex.value].totalSignals) * 10000) / 100;
      }

      return 0;
    });

    const showChannels = () => {
      emit('showChannels');
    };

    const showCalculator = () => {
      emit('showCalculator');
    };

    watch(() => props.channel, () => {
      if (props.channel) {
        setActiveChannel(props.channel);
      }
    });

    watch(() => props.channel?.subscribed, () => {
      if (props.channel && fullChannel.value && props.channel.id === fullChannel.value.id) {
        fullChannel.value.subscribed = props.channel.subscribed;
      }
    });

    watch(() => props.timeframe, () => recalculateStrategies());

    const selectedTimeframe = computed({
      get() {
        return props.timeframe;
      },
      set(newTimeframe) {
        emit('update:timeframe', newTimeframe);
      },
    });

    return {
      fullChannel,
      strategyIndex,
      chartTypes,
      chartType,
      isHistoryButtonShown,
      isHistoryButtonLoading,
      isSubscriptionLoading,
      currentChannel,
      profitableCount,
      selectedTimeframe,
      availableTimeframes,
      recalculateStrategies,
      toggleSubscription,
      loadMoreSignals,
      selectStrategy,
      showChannels,
      showCalculator,
    };
  },
});
