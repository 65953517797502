import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "language__content" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    class: "modal__language",
    name: "language",
    title: _ctx.$t('language.title')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["language__select", { active: _ctx.locale === language.code }]),
            onClick: ($event: any) => (_ctx.setLanguage(language.code))
          }, [
            _createElementVNode("span", null, _toDisplayString(language.title), 1)
          ], 10, _hoisted_2))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}