import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    title: _ctx.$t('profile.signin.title'),
    name: "auth",
    id: "auth"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Input, {
        title: _ctx.$t('profile.signin.email'),
        placeholder: "address@domain.com",
        value: _ctx.email.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email.value) = $event)),
        error: _ctx.email.error
      }, null, 8, ["title", "value", "error"]),
      _createVNode(_component_Input, {
        title: _ctx.$t('profile.signin.password'),
        type: "password",
        placeholder: "*******",
        value: _ctx.password.value,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password.value) = $event)),
        error: _ctx.password.error
      }, null, 8, ["title", "value", "error"]),
      _createElementVNode("a", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.store.commit(_ctx.SHOW_MODAL, 'reset')))
      }, _toDisplayString(_ctx.$t('profile.signin.forgot')), 1),
      _createVNode(_component_Button, {
        loading: _ctx.loading,
        onClick: _ctx.provideUserLogin
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('profile.buttons.signin')), 1)
        ]),
        _: 1
      }, 8, ["loading", "onClick"]),
      _createElementVNode("p", null, [
        _createTextVNode(_toDisplayString(_ctx.$t('profile.signin.signup')) + " ", 1),
        _createElementVNode("a", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.store.commit(_ctx.SHOW_MODAL, 'register')))
        }, _toDisplayString(_ctx.$t('profile.buttons.signup')), 1)
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}