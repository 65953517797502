import * as api from './users.api';
import {
  User,
  TokenObject,
  ReferralObject,
  ReferralLine,
} from './assets/user';

export const create = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  referrerId?: string,
): Promise<TokenObject> => {
  const payload = await api.create({
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    referrer_id: referrerId,
  }) as any;

  const result: TokenObject = {
    accessToken: payload.access_token,
    refreshToken: payload.refresh_token,
  };

  return result;
};

export const login = async (email: string, password: string): Promise<TokenObject> => {
  const payload = await api.login({ email, password }) as any;

  const result: TokenObject = {
    accessToken: payload.access_token,
    refreshToken: payload.refresh_token,
  };

  return result;
};

export const getMe = async (): Promise<User> => api.getMe();

export const refresh = async (refreshToken: string): Promise<TokenObject> => {
  const payload = await api.refresh(refreshToken) as any;

  const result: TokenObject = {
    accessToken: payload.access_token,
    refreshToken: payload.refresh_token,
  };

  return result;
};

export const update = async ({
  firstName,
  lastName,
  email,
}: {
  firstName?: any,
  lastName?: any,
  email?: any,
}): Promise<User> => api.updateUser({ first_name: firstName, last_name: lastName, email });

export const changePassword = async (password: string, oldPassword: string): Promise<TokenObject> => {
  const payload = await api.changePassword({ password, old_password: oldPassword }) as any;

  const result: TokenObject = {
    accessToken: payload.access_token,
    refreshToken: payload.refresh_token,
  };

  return result;
};

export const referral = async (): Promise<ReferralObject> => {
  const payload = await api.referral() as any;

  const result: ReferralObject = {
    balance: payload.balance,
    referrals: payload.referrals,
    monthlyReferrals: payload.monthly_referrals,
    income: payload.income,
    rewardRatio: payload.reward_ratio,
  };

  return result;
};

export const referralLine = async (line: number): Promise<ReferralLine> => {
  const payload = await api.referralLine(line) as any;

  const result: ReferralLine = {
    referrals: payload.referrals,
    monthly: payload.monthly,
    income: payload.income,
  };

  return result;
};
