import { InlineSelector } from '@/models/selector';

export const availableMarkets: InlineSelector[] = [
  {
    key: 'crypto',
    value: 'markets.crypto',
  },
  {
    key: 'forex',
    value: 'markets.forex',
  },
];

export const availableTimeframes: InlineSelector[] = [
  {
    key: '1w',
    value: '1w',
  },
  {
    key: '1M',
    value: '1M',
  },
  {
    key: '3M',
    value: '3M',
  },
  {
    key: '6M',
    value: '6M',
  },
  {
    key: '1Y',
    value: '1Y',
  },
];

export const availableChartTimeframes: InlineSelector[] = [
  {
    key: '1m',
    value: '1m',
  },
  {
    key: '5m',
    value: '5m',
  },
  {
    key: '15m',
    value: '15m',
  },
  {
    key: '30m',
    value: '30m',
  },
  {
    key: '1h',
    value: '1h',
  },
  {
    key: '2h',
    value: '2h',
  },
  {
    key: '4h',
    value: '4h',
  },
  {
    key: '1d',
    value: '1d',
  },
  {
    key: '1w',
    value: '1w',
  },
  {
    key: '1M',
    value: '1M',
  },
];
