import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSpinner = _resolveComponent("VueSpinner")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button", {'button-secondary': _ctx.secondary, 'button-linked': _ctx.linked, 'button-darkest': _ctx.darkest}]),
    disabled: _ctx.loading
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_VueSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.linked || !_ctx.loading)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 })
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}