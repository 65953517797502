
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AvatarComponent',
  setup() {
    const store = useStore();

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const isProfileLoaded = computed(() => store.getters.isProfileLoaded);
    const fullProfile = computed(() => store.state.user.profile);

    const initials = computed(() => {
      if (isAuthenticated.value === true && isProfileLoaded.value === true) {
        return fullProfile.value.first_name.substring(0, 1).toUpperCase() + fullProfile.value.last_name.substring(0, 1).toUpperCase();
      }
      return '';
    });

    return {
      fullProfile,
      initials,
    };
  },
});
