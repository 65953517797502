
import { defineComponent, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import ModalComponent from '@/components/ModalComponent.vue';
import Button from '@/components/ui/ButtonComponent.vue';
import Input from '@/components/ui/InputComponent.vue';

import { SHOW_MODAL } from '@/store/actions/modal';
import { SIGNIN_REQUEST } from '@/store/actions/user';

export default defineComponent({
  name: 'AuthorizationModal',
  components: {
    ModalComponent,
    Button,
    Input,
  },
  setup() {
    const store = useStore();

    const { t } = useI18n({
      useScope: 'global',
    });

    const loading = ref(false);

    const formData = reactive({
      email: {
        value: '',
        error: undefined,
      },
      password: {
        value: '',
        error: undefined,
      },
    }) as any;

    const provideUserLogin = () => {
      loading.value = true;

      store.dispatch(SIGNIN_REQUEST, {
        email: formData.email.value,
        password: formData.password.value,
      }).then(() => {
        window.location.reload();
      }).catch((error) => {
        if (error.data) {
          const { message, data } = error.data;

          if (data.field && formData[data.field]) {
            formData[data.field].error = message;
          } else {
            alert(message);
          }
        } else {
          alert(t('profile.signin.error'));
        }
      }).finally(() => {
        loading.value = false;
      });
    };

    return {
      ...formData,
      provideUserLogin,
      loading,
      store,
      SHOW_MODAL,
    };
  },
});
