import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    title: _ctx.$t('menu.deposit'),
    name: "deposit",
    id: "deposit"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Input, {
        title: _ctx.$t('menu.amount'),
        placeholder: "100",
        value: _ctx.amount.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.amount.value) = $event)),
        error: _ctx.amount.error
      }, null, 8, ["title", "value", "error"]),
      _createVNode(_component_Button, {
        loading: _ctx.loading,
        onClick: _ctx.createPayment
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('menu.confirm')), 1)
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ]),
    _: 1
  }, 8, ["title"]))
}