import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a1cbeb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channels-list__inner" }
const _hoisted_2 = { class: "channels-entities" }
const _hoisted_3 = {
  key: 0,
  class: "channels-loading"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "channels-entity__title" }
const _hoisted_7 = { class: "channels-entity__name" }
const _hoisted_8 = {
  key: 0,
  class: "channels-entity__verify"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSelector = _resolveComponent("InlineSelector")!
  const _component_VueSpinner = _resolveComponent("VueSpinner")!
  const _component_StarSvg = _resolveComponent("StarSvg")!
  const _component_VerifySvg = _resolveComponent("VerifySvg")!
  const _component_DirectionIcon = _resolveComponent("DirectionIcon")!
  const _component_CustomScrollbar = _resolveComponent("CustomScrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["channels-list", {'channels-list__mobile': _ctx.isMobileShown}])
  }, [
    _createVNode(_component_CustomScrollbar, { style: {"height":"100%"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "channels-list__close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMobile()))
          }),
          _createVNode(_component_InlineSelector, {
            values: _ctx.availableTimeframes,
            value: _ctx.selectedTimeframe,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTimeframe) = $event)),
            ref: "timeframesElement"
          }, null, 8, ["values", "value"]),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.isChannelsLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_VueSpinner, {
                    size: "30",
                    color: "var(--sh-primary)"
                  })
                ]))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.channels, (channel, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "channels-entity"
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["channels-entity__subscription", {subscribed: channel.subscribed}]),
                      onClick: ($event: any) => (_ctx.toggleSubscription(channel))
                    }, [
                      _createVNode(_component_StarSvg)
                    ], 10, _hoisted_4),
                    _createElementVNode("div", {
                      class: "channels-entity__info",
                      onClick: ($event: any) => (_ctx.selectChannel(channel))
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createTextVNode(_toDisplayString(channel.name) + " ", 1),
                          (channel.name === 'Cryptonec')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode(_component_VerifySvg)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["channels-entity__income", {
                  'sh-green': (channel.profit || 0) >= 0,
                  'sh-red': (channel.profit || 0) < 0,
                }])
                      }, [
                        _createVNode(_component_DirectionIcon, {
                          direction: (channel.profit || 0) >= 0 ? 'up' : 'down'
                        }, null, 8, ["direction"]),
                        _createTextVNode(" " + _toDisplayString(Math.abs(channel.profit || 0)) + "% ", 1)
                      ], 2)
                    ], 8, _hoisted_5)
                  ]))
                }), 128))
          ])
        ])
      ]),
      _: 1
    })
  ], 2))
}