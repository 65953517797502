
import { defineComponent } from 'vue';

import ModalComponent from '@/components/ModalComponent.vue';

export default defineComponent({
  name: 'CopyTradingModal',
  components: {
    ModalComponent,
  },
});
