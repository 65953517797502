
import { defineComponent } from 'vue';

import { VueSpinner } from 'vue3-spinners';

export default defineComponent({
  name: 'ButtonComponent',
  components: {
    VueSpinner,
  },
  props: {
    text: String,
    loading: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
    darkest: { type: Boolean, default: false },
    linked: { type: Boolean, default: false },
  },
});
