import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4259b23a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channels" }
const _hoisted_2 = { class: "channels-left" }
const _hoisted_3 = { class: "channels-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChannelsList = _resolveComponent("ChannelsList")!
  const _component_ChannelComponent = _resolveComponent("ChannelComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ChannelsList, {
        market: _ctx.market,
        "onUpdate:market": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.market) = $event)),
        timeframe: _ctx.timeframe,
        "onUpdate:timeframe": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.timeframe) = $event)),
        channel: _ctx.activeChannel,
        "onUpdate:channel": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeChannel) = $event)),
        ref: "channelsList"
      }, null, 8, ["market", "timeframe", "channel"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ChannelComponent, {
        market: _ctx.market,
        channel: _ctx.activeChannel,
        "onUpdate:channel": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeChannel) = $event)),
        timeframe: _ctx.timeframe,
        "onUpdate:timeframe": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.timeframe) = $event)),
        onShowChannels: _ctx.showChannels,
        onShowCalculator: _ctx.showCalculator,
        ref: "channelComponent"
      }, null, 8, ["market", "channel", "timeframe", "onShowChannels", "onShowCalculator"])
    ])
  ]))
}