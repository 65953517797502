import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    class: "copytrading",
    name: "copytrading",
    title: _ctx.$t('copytrading.soon')
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('copytrading.content')), 1)
    ]),
    _: 1
  }, 8, ["title"]))
}