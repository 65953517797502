
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  computed,
  PropType,
  nextTick,
} from 'vue';
import { useStore } from 'vuex';

import { VueSpinner } from 'vue3-spinners';

import CustomScrollbar from 'custom-vue-scrollbar';

import InlineSelector from '@/components/ui/InlineSelector.vue';
import DirectionIcon from '@/components/icons/DirectionIcon.vue';
import StarSvg from '@/components/icons/StarSvg.vue';
import VerifySvg from '@/components/icons/VerifySvg.vue';

import { availableMarkets, availableTimeframes } from '@/data/markets';

import { InlineSelector as InlineSelectorModel } from '@/models/selector';

import { Channel } from '@/rest-api/signals/assets/channels';
import * as api from '@/rest-api/signals';

import { SHOW_MODAL } from '@/store/actions/modal';

export default defineComponent({
  name: 'ChannelsList',
  components: {
    VueSpinner,
    CustomScrollbar,
    InlineSelector,
    DirectionIcon,
    StarSvg,
    VerifySvg,
  },
  props: {
    market: { type: Object as PropType<InlineSelectorModel>, required: false, default: availableMarkets[0] },
    timeframe: { type: Object as PropType<InlineSelectorModel>, required: false, default: availableTimeframes[0] },
    channel: { type: Object as PropType<Channel>, required: false },
  },
  emits: ['update:channel', 'update:market', 'update:timeframe'],
  setup(props, { emit }) {
    const store = useStore();

    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    const isMobileShown = ref(false);

    const channels = ref<Channel[] | undefined>();

    const marketsElement = ref();
    const timeframesElement = ref();

    const activeChannel = computed<Channel | undefined>({
      get() {
        return props.channel;
      },
      set(newChannel) {
        emit('update:channel', newChannel);
      },
    });

    const selectedMarket = computed<InlineSelectorModel>({
      get() {
        return props.market;
      },
      set(newMarket) {
        emit('update:market', newMarket);
      },
    });

    const selectedTimeframe = computed<InlineSelectorModel>({
      get() {
        return props.timeframe;
      },
      set(newTimeframe) {
        emit('update:timeframe', newTimeframe);
      },
    });

    const isChannelsLoading = ref(true);

    const update = (isMounted = false) => {
      isChannelsLoading.value = true;

      api.getChannels(selectedTimeframe.value.key, selectedMarket.value.key).then((updated) => {
        channels.value = updated;
        isChannelsLoading.value = false;

        if (updated.length > 0 && isMounted) {
          activeChannel.value = updated[0];   // eslint-disable-line
        }
      });
    };

    const toggleSubscription = (channel: Channel) => {
      if (!isAuthenticated.value) {
        store.commit(SHOW_MODAL, 'auth');
        return;
      }

      api.subscribe(channel.id).then((payload) => {
        channel.subscribed = payload.status;  // eslint-disable-line

        if (activeChannel.value !== undefined && activeChannel.value.id === channel.id) {
          activeChannel.value.subscribed = payload.status;
        }
      });
    };

    const toggleMobile = (hide = false) => {
      isMobileShown.value = hide ? false : !isMobileShown.value;

      nextTick(() => {
        // marketsElement.value.updateSlider();
        timeframesElement.value.updateSlider();
      });

      return isMobileShown.value;
    };

    const selectChannel = (channel: Channel) => {
      activeChannel.value = channel;
      toggleMobile(true);
    };

    onMounted(() => {
      update(true);
    });

    watch([selectedMarket, selectedTimeframe], ([newMarket, newTimeframe]) => {
      localStorage.setItem('channels-market', newMarket.key);
      localStorage.setItem('channels-timeframe', newTimeframe.key);

      update();
    });

    return {
      channels,
      activeChannel,
      isChannelsLoading,
      availableMarkets,
      availableTimeframes,
      selectedMarket,
      selectedTimeframe,
      isMobileShown,
      update,
      toggleSubscription,
      selectChannel,
      toggleMobile,
      marketsElement,
      timeframesElement,
    };
  },
});
