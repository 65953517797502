
import {
  defineComponent,
  computed,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import Button from '@/components/ui/ButtonComponent.vue';
import AvatarComponent from '@/components/ui/AvatarComponent.vue';

import ArrowSvg from '@/components/icons/ArrowSvg.vue';
import TelegramSvg from '@/components/icons/TelegramSvg.vue';
import LanguageSvg from '@/components/icons/LanguageSvg.vue';

import { SHOW_MODAL } from '@/store/actions/modal';

export default defineComponent({
  name: 'HeaderComponent',
  components: {
    Button,
    AvatarComponent,
    ArrowSvg,
    TelegramSvg,
    LanguageSvg,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const fullProfile = computed(() => store.state.user.profile);
    const userProfile = computed(() => store.getters.jwtProfile);

    const isMobileMenuShown = ref(false);

    const showDeposit = () => {
      store.commit(SHOW_MODAL, 'deposit');
    };

    const showLogin = () => {
      store.commit(SHOW_MODAL, 'auth');
    };

    const showRegistration = () => {
      store.commit(SHOW_MODAL, 'register');
    };

    const cabinet = () => {
      router.push({ path: '/cabinet' });
    };

    const showLanguage = () => {
      store.commit(SHOW_MODAL, 'language');
    };

    const mainPage = () => {
      router.push({ path: '/' });
    };

    const toggleMobileMenu = () => {
      isMobileMenuShown.value = !isMobileMenuShown.value;
    };

    watch(route, () => {
      isMobileMenuShown.value = false;
    });

    return {
      showDeposit,
      showLogin,
      showRegistration,
      showLanguage,
      isAuthenticated,
      fullProfile,
      userProfile,
      isMobileMenuShown,
      cabinet,
      mainPage,
      toggleMobileMenu,
    };
  },
});
