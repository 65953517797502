export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';

export const PASSWORD_REQUEST = 'PASSWORD_REQUEST';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export const USER_REQUEST = 'USER_REQUEST';

export const USER_SUCCESS = 'USER_SUCCESS';

export const USER_LOGOUT = 'USER_LOGOUT';
