
import {
  defineComponent,
  ref,
  onMounted,
  PropType,
  watch,
  nextTick,
} from 'vue';

import { Line, Bar } from 'vue-chartjs';

import { ChannelStrategy } from '@/rest-api/signals/assets/channels';

export default defineComponent({
  name: 'StatisticChart',
  components: {
    Line,
    Bar,
  },
  props: {
    strategy: { type: Object as PropType<ChannelStrategy>, required: true },
    type: String,
  },
  setup(props) {
    const data: any = ref({
      datasets: [],
    });

    const options = ref({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
          titleFont: {
            size: 14,
            family: 'Rubik',
          },
          bodyFont: {
            size: 16,
            family: 'Rubik',
            style: 'normal',
          },
          callbacks: {
            label: (context: any) => `${context.parsed.y}$`,
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        y: {
          grid: {
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            display: true,
            padding: 10,
            color: '#b2b9bf',
            font: {
              size: 11,
              family: 'Rubik',
              style: 'normal',
              lineHeight: 2,
            },
          },
        },
        x: {
          afterFit: (scale: any) => {
            scale.paddingLeft -= 20;   // eslint-disable-line
            scale.paddingRight -= 20;   // eslint-disable-line
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            display: true,
            color: '#b2b9bf',
            padding: 20,
            font: {
              size: 11,
              family: 'Rubik',
              style: 'normal',
              lineHeight: 2,
            },
          },
        },
      },
    }) as any;

    const updateChart = () => {
      nextTick(() => {
        const elem = document.getElementById(props.strategy.id) as HTMLCanvasElement;
        const ctx = elem.getContext('2d') as CanvasRenderingContext2D;

        const gradient = ctx.createLinearGradient(0, 230, 0, 50);

        gradient.addColorStop(1, 'rgba(4, 93, 233, 0.4)');
        gradient.addColorStop(0.4, 'rgba(4, 93, 233, 0.0)');
        gradient.addColorStop(0, 'rgba(4, 93, 233, 0)');

        const labels: string[] = [];
        const dataset: number[] = [];

        props.strategy.points.forEach((point) => {
          labels.push(point.date.split('-').splice(1, 2).join('-'));
          dataset.push(Math.round(point.value * 100) / 100);
        });

        data.value = {
          labels,
          datasets: [
            {
              label: 'Profit',
              tension: 0.4,
              borderWidth: 3,
              pointRadius: 0,
              backgroundColor: gradient,
              borderColor: '#045de9',
              fill: true,
              maxBarThickness: 6,
              data: dataset,
            },
          ],
        };
      });
    };

    watch(() => props.strategy, () => {
      updateChart();
    });

    onMounted(() => {
      updateChart();
    });

    return { data, options };
  },
});
