import * as api from './signals.api';
import {
  Channel,
  Signal,
  CompleteChannel,
  ChannelStrategy,
  ChannelSubscription,
  ActiveSignal,
  SimpleChannelStrategy,
  ExchangeConnection,
  TradingSettings,
  TakeProfitSettings,
} from './assets';

export const getChannels = async (
  period = '1w',
  market = 'crypto',
): Promise<Channel[]> => {
  try {
    const payload = api.getChannels({ period, market });
    return payload;
  } catch (error) {
    return [];
  }
};

export const getChannel = async (
  channelId: number,
  period: string,
): Promise<CompleteChannel> => {
  const payload = await api.getChannel({ id: channelId, period });

  payload.ratingPosition = (payload as any).rating_position;

  payload.strategies.forEach((strategy) => {
    /* eslint-disable */
    const currentStrategy = strategy as any;

    if ('total_signals' in strategy) {
      strategy.totalSignals = currentStrategy.total_signals as number;
    }

    if ('profit_signals' in strategy) {
      strategy.profitSignals = currentStrategy.profit_signals as number;
    }

    if ('chart_id' in strategy) {
      strategy.chartId = currentStrategy.chart_id as number;
    }
    /* eslint-enable */
  });

  payload.signals.forEach((signal) => {
    /* eslint-disable */
    const currentSignal = signal as any;

    if ('completed_take_profits' in signal) {
      signal.completedTakeProfits = currentSignal.completed_take_profits as number;
    }

    if ('total_take_profits' in signal) {
      signal.totalTakeProfits = currentSignal.total_take_profits as number;
    }
    /* eslint-enable */
  });

  return payload;
};

export const getSignals = async (
  channelId: number,
  offset: number,
): Promise<Signal[]> => {
  const payload = await api.getSignals({ id: channelId, offset });

  payload.forEach((signal) => {
    /* eslint-disable */
    const currentSignal = signal as any;

    if ('completed_take_profits' in signal) {
      signal.completedTakeProfits = currentSignal.completed_take_profits as number;
    }

    if ('total_take_profits' in signal) {
      signal.totalTakeProfits = currentSignal.total_take_profits as number;
    }
    /* eslint-enable */
  });

  return payload;
};

export const calculate = async (
  id: number,
  deposit: number,
  percentage: number,
  leverage: number,
  simultaneous: number,
  period: string,
): Promise<ChannelStrategy> => {
  const payload = await api.calculate(id, deposit, percentage, leverage, simultaneous, period);

  /* eslint-disable */
  const currentStrategy = payload as any;

  if ('total_signals' in payload) {
    payload.totalSignals = currentStrategy.total_signals as number;
  }

  if ('profit_signals' in payload) {
    payload.profitSignals = currentStrategy.profit_signals as number;
  }

  if ('chart_id' in payload) {
    payload.chartId = currentStrategy.chart_id as number;
  }
  /* eslint-enable */

  return payload;
};

export const subscribe = async (channelId: number): Promise<ChannelSubscription> => api.subscribe(channelId);

export const getActiveSignals = async (): Promise<ActiveSignal[]> => {
  const payload = await api.getActiveSignals() as any;

  /* eslint-disable */
  payload.forEach((signal: any) => {
    if ('telegram_channel' in signal) {
      signal.telegramChannel = signal.telegram_channel;
    }
  
    if ('take_profits' in signal) {
      signal.takeProfits = signal.take_profits;
  
      signal.takeProfits.forEach((item: any) => {
        item.isCompleted = item.is_completed;
        item.completedAt = item.completed_at;
      });
    }

    if ('created_at' in signal) {
      signal.createdAt = signal.created_at;
    }

    if ('opened_at' in signal) {
      signal.openedAt = signal.opened_at;
    }
  });
  /* eslint-enable */

  return payload;
};

export const saveStrategy = async (
  name: string,
  deposit: number,
  percentage: number,
  leverage: number,
  simultaneous: number,
): Promise<SimpleChannelStrategy> => api.saveStrategy({
  name,
  deposit,
  percentage,
  leverage,
  simultaneous,
});

export const getSubscribedChannels = async (): Promise<Channel[]> => api.getSubscribedChannels();

export const getUserStrategy = async (): Promise<ChannelStrategy[]> => api.getUserStrategy();

export const getExchanges = async (): Promise<string[]> => api.getExchanges();

export const getApiSettings = async (): Promise<ExchangeConnection> => api.getApiSettings();

export const updateApiSettings = async (
  exchangeName: string,
  apiKey: string,
  apiSecret: string,
): Promise<ExchangeConnection> => api.updateApiSettings(exchangeName, apiKey, apiSecret);

export const getTradingSettings = async (): Promise<TradingSettings> => api.getTradingSettings();

export const updateTradingSettings = (
  takeProfit: number,
  simultaneous: number,
  leverage: number,
  percentage: number,
  takeProfitSettings: TakeProfitSettings[],
  reverse: boolean,
  stopLoss?: number,
): Promise<TradingSettings> => api.updateTradingSettings(
  takeProfit,
  simultaneous,
  leverage,
  percentage,
  takeProfitSettings,
  reverse,
  stopLoss,
);
