
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import HeaderComponent from '@/components/HeaderComponent.vue';
import LoginModal from '@/components/modals/LoginModal.vue';
import RegistrationModal from '@/components/modals/RegistrationModal.vue';
import CopyTradingModal from '@/components/modals/CopyTradingModal.vue';
import ChangeLanguageModal from '@/components/modals/ChangeLanguageModal.vue';
import DepositModal from '@/components/modals/DepositModal.vue';

import { USER_REQUEST } from './store/actions/user';

export default defineComponent({
  name: 'App',
  components: {
    HeaderComponent,
    LoginModal,
    RegistrationModal,
    CopyTradingModal,
    ChangeLanguageModal,
    DepositModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    if (store.getters.isAuthenticated && !store.getters.isProfileLoaded) {
      store.dispatch(USER_REQUEST);
    }

    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    window.addEventListener('resize', appHeight);

    onMounted(async () => {
      appHeight();

      await router.isReady();

      const referrerId = route.query.invite;

      if (referrerId !== undefined) {
        localStorage.setItem('referrer-id', referrerId as string);
      }
    });
  },
});
