import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LoginModal = _resolveComponent("LoginModal")!
  const _component_RegistrationModal = _resolveComponent("RegistrationModal")!
  const _component_CopyTradingModal = _resolveComponent("CopyTradingModal")!
  const _component_ChangeLanguageModal = _resolveComponent("ChangeLanguageModal")!
  const _component_DepositModal = _resolveComponent("DepositModal")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HeaderComponent),
      _createVNode(_component_router_view, { style: {"padding-top":"5px"} })
    ]),
    _createVNode(_component_LoginModal),
    _createVNode(_component_RegistrationModal),
    _createVNode(_component_CopyTradingModal),
    _createVNode(_component_ChangeLanguageModal),
    _createVNode(_component_DepositModal),
    _createVNode(_component_notifications, { classes: "sg__notify" })
  ], 64))
}