
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

import { HIDE_MODAL } from '@/store/actions/modal';

export default defineComponent({
  name: 'ModalComponent',
  props: {
    title: { type: String, required: false },
    name: String,
  },
  setup(props) {
    const store = useStore();

    const hideModal = (event: any) => {
      const classList = ['modal', 'modal-close'];

      if (classList.some((className) => event.target.classList.contains(className))) {
        store.commit(HIDE_MODAL);
      }
    };

    return {
      hideModal,
      openedModal: computed(() => store.state.modal.name),
      ...props,
    };
  },
});
