import axios, { AxiosRequestConfig } from 'axios';

import config from './api.config';
import ApiError from './api.error';

const throwApiError = ({ data = {}, status = 500 }) => {
  throw new ApiError(data, status);
};

const generalError = {
  _global: ['Unexpected error occured.'],
};

const httpRequest = (method: string) => async (url: string, payload: any = {}, isAnonymous = false): Promise<any> => {
  let urlWithSlash = url;

  if (urlWithSlash[0] !== '/') {
    urlWithSlash = `/${urlWithSlash}`;
  }

  if (urlWithSlash[urlWithSlash.length - 1] !== '/') {
    urlWithSlash = `${urlWithSlash}/`;
  }

  const options: AxiosRequestConfig = {
    method,
    url: `${config.url}${urlWithSlash}`,
  };

  if (payload) {
    if (payload.blob) {
      options.responseType = 'blob';
    }

    if (method === 'get') {
      options.params = payload;
    } else if (method === 'post') {
      options.data = payload;
    }
  }

  const token = localStorage.getItem('access-token');

  if (token && !isAnonymous) {
    options.headers = {
      Authorization: `Token ${token}`,
    };
  }

  let response;

  try {
    response = await axios(options);
  } catch (err: any) {
    if (!err.response) {
      throwApiError({
        data: { errors: generalError },
        status: 500,
      });
      return null;
    }

    response = err.response;
    response.data = response.data || {};
    response.status = response.status || 500;

    // if (response.status === 403) {
    //   const { data } = response.data;

    //   if (data.code === 'authentication_failed') {
    //     window.location.reload();
    //     return null;
    //   }
    // }

    throwApiError(response);
  }

  response.data = response.data || {};
  response.status = response.status || 500;

  if (response.status >= 200 && response.status < 300) {
    return response.data.data;
  }

  response.data.errors = response.data.errors || generalError;
  throwApiError(response);
  return null;
};

export const getRequest = httpRequest('get');
export const postRequest = httpRequest('post');

const apiClient = {
  get: getRequest,
  post: postRequest,
};

export default apiClient;
