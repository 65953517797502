import { apiClient } from '@/rest-api/api';
import {
  Channel,
  Signal,
  CompleteChannel,
  ChannelStrategy,
  ChannelSubscription,
  ActiveSignal,
  SimpleChannelStrategy,
  ExchangeConnection,
  TradingSettings,
  TakeProfitSettings,
} from './assets';

export const getChannels = ({ period, market }: { period: string, market: string }): Promise<Channel[]> => apiClient.get('signals/channels/list', { period, market });

export const getChannel = ({ id, period }: { id: number, period: string }): Promise<CompleteChannel> => apiClient.get(`signals/channels/${id}`, { period });

export const getSignals = ({ id, offset }: { id: number, offset: number }): Promise<Signal[]> => apiClient.get(`signals/channels/${id}/list`, { offset });

export const calculate = (
  id: number,
  deposit: number,
  percentage: number,
  leverage: number,
  simultaneous: number,
  period: string,
): Promise<ChannelStrategy> => apiClient.post(`signals/channels/${id}/calculate`, {
  deposit,
  percentage,
  leverage,
  simultaneous,
  period,
});

export const subscribe = (channelId: number): Promise<ChannelSubscription> => apiClient.get(`signals/channels/${channelId}/subscribe`);

export const getActiveSignals = (): Promise<ActiveSignal[]> => apiClient.get('signals/list/');

export const saveStrategy = (payload: object): Promise<SimpleChannelStrategy> => apiClient.post('signals/saveStrategy', payload);

export const getSubscribedChannels = (): Promise<Channel[]> => apiClient.get('signals/channels/subscribed');

export const getUserStrategy = (): Promise<ChannelStrategy[]> => apiClient.get('signals/strategy/my');

export const getExchanges = (): Promise<string[]> => apiClient.get('signals/exchanges');

export const getApiSettings = (): Promise<ExchangeConnection> => apiClient.get('signals/api');

export const updateApiSettings = (
  exchangeName: string,
  apiKey: string,
  apiSecret: string,
): Promise<ExchangeConnection> => apiClient.post('signals/api', { exchange_name: exchangeName, api_key: apiKey, api_secret: apiSecret });

export const getTradingSettings = (): Promise<TradingSettings> => apiClient.get('signals/api/settings');

export const updateTradingSettings = (
  takeProfit: number,
  simultaneous: number,
  leverage: number,
  percentage: number,
  takeProfitSettings: TakeProfitSettings[],
  reverse: boolean,
  stopLoss?: number,
): Promise<TradingSettings> => apiClient.post('signals/api/settings', {
  simultaneous,
  leverage,
  percentage,
  take_profit: takeProfit,
  take_profit_settings: takeProfitSettings,
  reverse,
  stop_loss: stopLoss,
});
