
import {
  defineComponent,
  ref,
} from 'vue';

import { Channel } from '@/rest-api/signals/assets/channels';

import { InlineSelector } from '@/models/selector';

import { availableMarkets, availableTimeframes } from '@/data/markets';

import ChannelsList from './ChannelsList.vue';
// import Calculator from './CalculatorComponent.vue';
import ChannelComponent from './ChannelComponent.vue';

export default defineComponent({
  name: 'ChannelsView',
  components: {
    ChannelsList,
    // Calculator,
    ChannelComponent,
  },
  setup() {
    const channelsList = ref();
    const calculatorComponent = ref();
    const channelComponent = ref();

    // const savedMarket = localStorage.getItem('channels-market') || undefined;
    // let defaultMarket: InlineSelector | undefined;

    // if (savedMarket) {
    //   availableMarkets.forEach((currentMarket) => {
    //     if (currentMarket.key === savedMarket) {
    //       defaultMarket = currentMarket;
    //     }
    //   });
    // }

    const savedTimeframe = localStorage.getItem('channels-timeframe') || undefined;
    let defaultTimeframe: InlineSelector | undefined;

    if (savedTimeframe) {
      availableTimeframes.forEach((currentTimeframe) => {
        if (currentTimeframe.key === savedTimeframe) {
          defaultTimeframe = currentTimeframe;
        }
      });
    }

    const market = ref<InlineSelector>(availableMarkets[0]);
    const timeframe = ref<InlineSelector>(defaultTimeframe || availableTimeframes[0]);

    const activeChannel = ref<Channel>();

    const reloadChannels = () => {
      channelsList.value.update();
      channelComponent.value.recalculateStrategies();
    };

    const showChannels = () => {
      channelsList.value.toggleMobile();
    };

    const showCalculator = () => {
      calculatorComponent.value.toggleMobile();
    };

    return {
      channelsList,
      calculatorComponent,
      channelComponent,
      market,
      timeframe,
      activeChannel,
      reloadChannels,
      showChannels,
      showCalculator,
    };
  },
});
