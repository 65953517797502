import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chart__statistic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Line = _resolveComponent("Line")!
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type === 'line')
      ? (_openBlock(), _createBlock(_component_Line, {
          key: 0,
          data: _ctx.data,
          options: _ctx.options,
          id: _ctx.strategy.id,
          width: null,
          height: null
        }, null, 8, ["data", "options", "id"]))
      : _createCommentVNode("", true),
    (_ctx.type === 'bar')
      ? (_openBlock(), _createBlock(_component_Bar, {
          key: 1,
          data: _ctx.data,
          options: _ctx.options,
          id: _ctx.strategy.id,
          width: null,
          height: null
        }, null, 8, ["data", "options", "id"]))
      : _createCommentVNode("", true)
  ]))
}