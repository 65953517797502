import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4902a8e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "inline-selector",
  ref: "selector"
}
const _hoisted_2 = {
  class: "inline-slider",
  ref: "slider"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: _normalizeClass({ active: item.key === _ctx.innerValue.key }),
        onClick: ($event: any) => (_ctx.innerValue = item)
      }, _toDisplayString(_ctx.$t(item.value)), 11, _hoisted_3))
    }), 128))
  ], 512))
}