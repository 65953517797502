import { apiClient } from '@/rest-api/api';
import {
  User,
  TokenObject,
  ReferralObject,
  ReferralLine,
} from './assets/user';

export const create = (payload: object): Promise<TokenObject> => apiClient.post('users/create', payload);

export const login = (payload: object): Promise<TokenObject> => apiClient.post('users/login', payload);

export const getMe = (): Promise<User> => apiClient.get('users/me');

export const refresh = (refreshToken: string): Promise<object> => apiClient.post('users/refresh', { refresh_token: refreshToken }, true);

export const updateUser = (payload: object): Promise<User> => apiClient.post('users/me', payload);

export const changePassword = (payload: object): Promise<TokenObject> => apiClient.post('users/changePassword', payload);

export const referral = (): Promise<ReferralObject> => apiClient.get('users/referral');

export const referralLine = (line: number): Promise<ReferralLine> => apiClient.get(`users/referral/${line}`);
