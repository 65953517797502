import { apiClient } from '@/rest-api/api';
import {
  BooleanStatus,
  Payment,
} from './assets/payments';

export const enableTest = (): Promise<BooleanStatus> => apiClient.get('payments/test');

export const renew = (): Promise<Payment> => apiClient.post('payments/create');

export const payment = (amount: number): Promise<Payment> => apiClient.post('payments/deposit', { amount });
