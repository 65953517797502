
import {
  defineComponent,
  PropType,
  computed,
  ref,
  onMounted,
  onUpdated,
  onUnmounted,
} from 'vue';

import { InlineSelector } from '@/models/selector';

export default defineComponent({
  name: 'InlineSelector',
  props: {
    values: { type: Array as PropType<InlineSelector[]>, required: true },
    value: { type: Object as PropType<InlineSelector>, required: true },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const selector = ref();
    const slider = ref();

    const updateSlider = () => {
      const selectedItem = selector.value.querySelector('li.active');

      if (selectedItem) {
        const index = Array.prototype.indexOf.call(selector.value.children, selectedItem) - 1;

        const { width } = selectedItem.getBoundingClientRect();

        slider.value.style.left = `calc(${index * width}px + var(--selector-padding) * ${index})`;
        slider.value.style.width = `${width}px`;
      }
    };

    const innerValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit('update:value', newValue);
      },
    });

    onMounted(() => {
      window.addEventListener('resize', updateSlider);
      updateSlider();
    });

    onUpdated(() => {
      updateSlider();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateSlider);
    });

    return {
      selector,
      slider,
      innerValue,
      updateSlider,
    };
  },
});
