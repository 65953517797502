import { InlineSelector } from '@/models/selector';

export const chartTypes: InlineSelector[] = [
  {
    key: 'line',
    value: 'channels.charts.line',
  },
  {
    key: 'bar',
    value: 'channels.charts.bar',
  },
];

export const periodValues = [
  {
    icon: '1w',
    title: '1 week',
    key: '1w',
  },
  {
    icon: '1M',
    title: '1 month',
    key: '1M',
  },
  {
    icon: '3M',
    title: '3 months',
    key: '3M',
  },
  {
    icon: '6M',
    title: 'Half a year',
    key: '6M',
  },
  {
    icon: '1Y',
    title: '1 year',
    key: '1Y',
  },
];

export const tradingViewTimeframes: { [key: string]: string | number } = {
  '1m': 1,
  '5m': 5,
  '15m': 15,
  '30m': 30,
  '1h': 60,
  '2h': 120,
  '4h': 240,
  '1d': '1D',
  '1w': '1W',
  '1M': '1M',
};
