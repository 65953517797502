/* eslint-disable */
import {
  SHOW_MODAL,
  HIDE_MODAL,
} from '../actions/modal';

const state = { name: '' };

const mutations = {
  [SHOW_MODAL]: (storeState: any, modal: string) => {
    storeState.name = modal;
  },
  [HIDE_MODAL]: (storeState: any) => {
    storeState.name = undefined;
  },
};

export default {
  state,
  mutations,
};
