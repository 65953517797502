
import { defineComponent, ref, computed } from 'vue';

import { useNotification } from '@kyvg/vue3-notification';

import HelpSvg from '@/components/icons/HelpSvg.vue';

export default defineComponent({
  name: 'InputComponent',
  components: {
    HelpSvg,
  },
  props: {
    title: String,
    id: String,
    type: { type: String, default: 'text' },
    placeholder: { type: String, default: '' },
    value: [String, Number],
    help: { type: String, default: '' },
    error: { type: String, default: undefined },
    hasError: { type: Boolean, default: true },
    copy: { type: Boolean, default: false },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const { notify } = useNotification();

    const isHidden = ref(true);
    const inputField = ref();

    const innerValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit('update:value', newValue);
      },
    });

    const toggleVisibleInput = () => {
      isHidden.value = !isHidden.value;
    };

    const copyInput = () => {
      navigator.clipboard.writeText(innerValue.value as string);
      notify({
        title: '<div class="sg__notify-arrow"></div>',
        text: 'Copied to clipboard',
        duration: 5000,
        type: 'success',
      });
    };

    return {
      inputField,
      isHidden,
      innerValue,
      toggleVisibleInput,
      copyInput,
    };
  },
});
