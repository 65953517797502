
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import ModalComponent from '@/components/ModalComponent.vue';

import { HIDE_MODAL } from '@/store/actions/modal';

export default defineComponent({
  name: 'ChangeLanguageModal',
  components: {
    ModalComponent,
  },
  setup() {
    const store = useStore();

    const { locale } = useI18n({
      useScope: 'global',
    });

    const languages = [
      {
        title: 'English',
        code: 'en',
      },
      {
        title: 'Русский',
        code: 'ru',
      },
      {
        title: 'Español',
        code: 'es',
      },
    ];

    const setLanguage = (code: string) => {
      localStorage.setItem('language', code);
      locale.value = code;
      store.commit(HIDE_MODAL);
      window.location.reload();
    };

    return {
      languages,
      locale,
      setLanguage,
    };
  },
});
