/* eslint-disable */
import {
  SIGNUP_REQUEST,
  SIGNIN_REQUEST,
  PASSWORD_REQUEST,
  AUTH_SUCCESS,
  USER_REQUEST,
  USER_SUCCESS,
  USER_LOGOUT,
} from '../actions/user';

import jwtDecode from 'jwt-decode';

import * as api from '@/rest-api/users';
import { SignUpCredentials, User } from '@/rest-api/users/assets/user';

const state = {
  accessToken: localStorage.getItem('access-token') || '',
  refreshToken: localStorage.getItem('refresh-token') || '',
  profile: {},
};

const getters = {
  isAuthenticated: (state: any) => !!state.accessToken,
  isProfileLoaded: (state: any) => !!state.profile.id,
  jwtProfile: (state: any) => state.accessToken && jwtDecode(state.accessToken),
};

const actions = {
  [SIGNUP_REQUEST]: ({ commit, dispatch }: any, credentials: SignUpCredentials) => {
    return new Promise((resolve, reject) => {
      const referrerId = localStorage.getItem('referrer-id') || undefined;

      api.create(credentials.firstName, credentials.lastName, credentials.email, credentials.password, referrerId).then((payload) => {
        localStorage.setItem('access-token', payload.accessToken);
        localStorage.setItem('refresh-token', payload.refreshToken);

        commit(AUTH_SUCCESS, { accessToken: payload.accessToken, refreshToken: payload.refreshToken });

        dispatch(USER_REQUEST);

        resolve(payload);
      }).catch((error) => {
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');

        reject(error);
      });
    });
  },
  [SIGNIN_REQUEST]: ({ commit, dispatch }: any, { email, password }: { email: string, password: string }) => {
    return new Promise((resolve, reject) => {
      api.login(email, password).then((payload) => {
        localStorage.setItem('access-token', payload.accessToken);
        localStorage.setItem('refresh-token', payload.refreshToken);

        commit(AUTH_SUCCESS, { accessToken: payload.accessToken, refreshToken: payload.refreshToken });

        dispatch(USER_REQUEST);

        resolve(payload);
      }).catch((error) => {
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');

        reject(error);
      });
    });
  },
  [PASSWORD_REQUEST]: ({ commit, dispatch }: any, { password, oldPassword }: { password: string, oldPassword: string }) => {
    return new Promise((resolve, reject) => {
      api.changePassword(password, oldPassword).then((payload) => {
        localStorage.setItem('access-token', payload.accessToken);
        localStorage.setItem('refresh-token', payload.refreshToken);

        commit(AUTH_SUCCESS, { accessToken: payload.accessToken, refreshToken: payload.refreshToken });

        dispatch(USER_REQUEST);

        resolve(payload);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [USER_REQUEST]: ({ commit, dispatch }: any) => {
    api.getMe().then((payload) => {
      commit(USER_SUCCESS, payload);
    }).catch((error) => {
      if (error.data) {
        const { data } = error.data;

        if (data.code === 'authentication_failed') {
          const refreshToken = localStorage.getItem('refresh-token');

          if (refreshToken) {
            api.refresh(refreshToken).then((payload: any) => {
              localStorage.setItem('access-token', payload.accessToken);
              localStorage.setItem('refresh-token', payload.refreshToken);

              commit(AUTH_SUCCESS, { accessToken: payload.accessToken, refreshToken: payload.refreshToken });

              window.location.reload();
            }).catch(() => {
              dispatch(USER_LOGOUT);
            });
            return;
          }
        }
      }
      dispatch(USER_LOGOUT);
    });
  },
  [USER_LOGOUT]: ({ commit, dispatch }: any) => {
    return new Promise((resolve) => {
      commit(USER_LOGOUT);

      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');

      window.location.reload();

      resolve(undefined);
    });
  },
};

const mutations = {
  [AUTH_SUCCESS]: (currentState: any, { accessToken, refreshToken }: any) => {
    currentState.accessToken = accessToken;
    currentState.refreshToken = refreshToken;
  },
  [USER_SUCCESS]: (currentState: any, profile: User) => {
    currentState.profile = profile;
  },
  [USER_LOGOUT]: (currentState: any) => {
    currentState.accessToken = '';
    currentState.refreshToken = '';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
