import { createI18n, FallbackLocale } from 'vue-i18n';

import en from './en.json';
import ru from './ru.json';
import es from './es.json';

export const defaultLocale = 'en';

export const languages = {
  en,
  ru,
  es,
};

const messages = Object.assign(languages);

const language: string = localStorage.getItem('language') || navigator.language || defaultLocale;
const fallbackLocale: FallbackLocale = defaultLocale;

export const i18n = createI18n({
  legacy: false,
  locale: language,
  fallbackLocale,
  messages,
});
