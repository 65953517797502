
import { defineComponent, ref, reactive } from 'vue';
import { useStore } from 'vuex';

import ModalComponent from '@/components/ModalComponent.vue';
import Button from '@/components/ui/ButtonComponent.vue';
import Input from '@/components/ui/InputComponent.vue';

import { SHOW_MODAL } from '@/store/actions/modal';

import * as api from '@/rest-api/payments';

export default defineComponent({
  name: 'DepositModal',
  components: {
    ModalComponent,
    Button,
    Input,
  },
  setup() {
    const store = useStore();

    const loading = ref(false);

    const formData = reactive({
      amount: {
        value: '',
        error: undefined,
      },
    }) as any;

    const createPayment = () => {
      loading.value = false;

      const amount = Number(formData.amount.value);

      if (!amount || Number.isNaN(amount) || amount < 10) {
        formData.amount.error = 'Минимальная сумма платежа равняется 10$';
        return;
      }

      api.payment(amount).then((result) => {
        window.open(result.payUrl, '_blank');
      }).finally(() => {
        loading.value = false;
      });
    };

    return {
      ...formData,
      createPayment,
      loading,
      store,
      SHOW_MODAL,
    };
  },
});
