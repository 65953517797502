import * as api from './payments.api';
import {
  BooleanStatus,
  Payment,
} from './assets/payments';

export const enableTest = async (): Promise<BooleanStatus> => api.enableTest();

export const renew = async (): Promise<Payment> => {
  const response = await api.renew() as any;

  if ('pay_url' in response) {
    response.payUrl = response.pay_url;
  }

  return response;
};

export const payment = async (amount: number): Promise<Payment> => {
  const response = await api.payment(amount) as any;

  if ('pay_url' in response) {
    response.payUrl = response.pay_url;
  }

  return response;
};
